<template>
    <SmartLinkItemComponent custom-tag="div" class="split-content-block" :class="backgroundClass" v-if="model"
        :id="`_${model.system.id}`" :component="!model.system.workflow">
        <div :class="`container ${paddingOptions(data.paddingModifications)}`">
            <div class="row">
                <div :class="leftBlockClass">
                    <SmartLinkStandaloneElement custom-tag="div" class="col-left"
                        :codename="type.elements.left_content.codename">
                        <RichText :value="model.elements.leftContent.value" />
                    </SmartLinkStandaloneElement>
                </div>
                <div :class="rightBlockClass">
                    <SmartLinkStandaloneElement custom-tag="div" class="col-left"
                        :codename="type.elements.right_content.codename">
                        <RichText :value="model.elements.rightContent.value" />
                    </SmartLinkStandaloneElement>
                </div>
            </div>
        </div>
    </SmartLinkItemComponent>
</template>
<script lang="ts" setup>
import { type SplitContentBlockModel, contentTypes } from '~/models';
import type { AnchorNavigationBarItem } from '~/types/AnchorNavigationBarItem';
const type = contentTypes.split_content_block;
const props = defineProps<{ model: SplitContentBlockModel }>();

const data = computed(() => {
    return {
        background: firstOrDefault(props.model.elements.background.value)?.codename,
        anchorTagNavigationMenuTitle: props.model.elements.anchorTagNavigationMenuTitle.value,
        anchorTagNavigationShowInNavigation: firstOrDefault(props.model.elements.anchorTagNavigationShowInNavigation.value),
        layout: firstOrDefault(props.model.elements.layout.value)?.codename,
        paddingModifications: props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename)
    }
});

const navBarState = useState<AnchorNavigationBarItem[]>('navBarState');
const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder');

if (multipleChoiceToBoolean(props.model.elements.anchorTagNavigationShowInNavigation)) {

    navBarState.value.push({ text: props.model.elements.anchorTagNavigationMenuTitle.value, link: `#${props.model.system.id}`, index: AnchorNavBarOrder.value.indexOf(props.model.system.codename)});
}
const backgroundClass = computed(() => {
    return data.value.background == 'dark' ? 'bg-light-gray' : ''
});

const leftBlockClass = computed(() => {
    return data.value.layout == 'n30_70' ? 'offset-lg-1 col-lg-4' : 'offset-lg-1 col-lg-6'
})

const rightBlockClass = computed(() => {
    return data.value.layout == 'n30_70' ? 'col-lg-6' : 'col-lg-4'
})


</script>